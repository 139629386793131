<template>
  <div class="content">
    <div style="margin-top: 5px">
      <!-- Tìm kiếm xem -->
      <div class="button-click-left">
        <el-date-picker
            :clearable="true"
            class="button-left-status"
            style="width: 265px"
            value-format="yyyy-MM-dd"
            format="dd-MM-yyyy"
            v-model="dataSearch.dateList"
            type="daterange"
            unlink-panels
            range-separator="-"
            @change="searchByProperties()"
            start-placeholder="Ngày bắt đầu"
            end-placeholder="Ngày kết thúc"
        >
        </el-date-picker>
        <el-select
            class="button-left"
            v-model="dataSearch.idClass"
            @change="searchHeaderMethod()"
            placeholder="Chọn lớp"
            clearable
            style="margin-left: 5px"
        >
          <el-option
              v-for="item in listClass"
              :key="item.id"
              :label="item.className"
              :value="item.id"
          ></el-option>
        </el-select>

        <el-select
            v-if="getAppTypeUserLogin === 'plus'"
            class="button-left"
            v-model="dataSearch.albumType"
            @change="searchHeaderMethod()"
            placeholder="Tất cả"
            clearable
        >
          <el-option
              v-for="item in albumTypesOption"
              :key="item"
              :label="item"
              :value="item"
          ></el-option>
        </el-select>
      </div>

      <div
          v-if="checkPermission(['studentQuality_ablum_update'])"
          class="button-click row-data"
      >
        <el-dropdown>
          <router-link :to="{ path: '/student-quality/album/add' }">
            <el-button class="button-over" type="success">Tạo Album</el-button>
          </router-link>
        </el-dropdown>
        <el-dropdown @command="handleCommandAction">
          <el-button class="button-over" type="success">
            Tác vụ
            <i class="el-icon-caret-bottom"/>
          </el-button>
          <el-dropdown-menu class="el-dropdown-menu-container">
            <el-dropdown-item command="approveAlbum"
            >Duyệt Album
            </el-dropdown-item
            >
            <el-dropdown-item command="unApproveAlbum"
            >Hủy duyệt Album
            </el-dropdown-item
            >
            <el-dropdown-item command="deleteMultiAlbum"
            >Xóa Album
            </el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-row v-if="total > 0" style="
       display: flex;
       flex-wrap: wrap;
       width: 100%;
       margin-top: 10px;
       border: 1px solid rgb(96 99 102 / 19%);
       overflow-y: scroll; max-height: 730px;
       padding-left: 50px;"
    >
      <div v-for="albumResponse in albumResponseList" :key="albumResponse.id"
           style="flex: 0 0 223px; margin: 14px; width: 223px">
        <router-link
            :to="{ path: '/student-quality/album-detail/' + albumResponse.id }"
            style="text-decoration: none; height: 200px"
            class="router-link"
        >
          <span
              v-if="albumResponse.albumNew"
              style="background: orange; position: absolute; z-index: 1"
          >Mới</span>
          <div class="inline-block-div">
            <el-image
                v-if="albumResponse.urlPictureFirst != null"
                :src="albumResponse.urlShowPicture"
                style="width: 100%; height: 200px"
            ></el-image>
          </div>
          <div>
            <h4
                style="color: #30313499; text-align: left; margin-left: 5px;  overflow: hidden; max-height: 20px;
                text-overflow: ellipsis; white-space: nowrap;"
                class="demonstration"
            >{{ albumResponseAlbumName(albumResponse) }}</h4>
          </div>
        </router-link>
        <div>
          <span style="float: right; margin-right: 10px" class="demonstration">{{
              albumResponse.className
            }}</span>

          <span style="float: right; margin-right: 10px" class="demonstration">{{
              albumResponse.albumType
            }}</span>
          <span style="margin-right: 5px; float: right" class="demonstration"
          >{{ albumResponse.pictureNumber }}&nbsp;<i
              class="el-icon-picture"
              style="color: #6298d0"
          ></i
          ></span>
          <br/>
          <el-checkbox
              style="margin-left: 5px"
              v-model="albumResponse.checkApporove"
              @change="
            checkApproveAlbum(albumResponse.id, albumResponse.checkApporove)
          "
              albumResponse.checkApprove
          ></el-checkbox>
          <span style="float: right; margin-right: 10px" class="demonstration">{{
              albumResponse.createdDate | formatDateTime
            }}</span>
          <div
              v-if="albumResponse.albumView"
              style="
            background: #67c23a;
            color: white;
            font-size: 20px;
            text-align: center;
          "
          >
            <span> Đang hiện</span>
            <el-button
                @click="download1(albumResponse)"
                :loading="albumResponse.checkDownload"
                style="background: #67c23a; margin-left: 10px; border: none"
            >
              <i style="color: white" class="el-icon-download" v-if="!albumResponse.checkDownload"></i>
            </el-button>

            <el-button
                v-if="checkPermission(['studentQuality_ablum_update'])"
                @click="deleteAlbum(albumResponse.id)"
                style="background: rgb(103, 194, 58); border: none"
            >
              <i style="color: white" class="el-icon-delete"></i>
            </el-button>
          </div>
          <div
              v-else
              style="
            background: #409eff;
            color: white;
            font-size: 20px;
            text-align: center;
          "
          >
            <span>Đã ẩn</span>
            <el-button
                @click="download1(albumResponse.id)"
                style="background: #409eff; margin-left: 10px; border: none"
            >
              <i style="color: white" class="el-icon-download"></i>
            </el-button>

            <el-button
                @click="deleteAlbum(albumResponse.id)"
                style="background: #409eff; border: none"
            >
              <i style="color: white" class="el-icon-delete"></i>
            </el-button>
          </div>
        </div>
      </div>
    </el-row>

    <el-row v-else style="
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 10px;
      border: 1px solid rgba(96, 99, 102, 0.19);
      max-height: 730px;
      padding-left: 50px;
      height: 100px;
      justify-content: center;
      align-items: center;
      color: #4b9af9;
    ">
      <div>Không có dữ liệu!</div>
    </el-row>

    <div
        v-if="total > 0"
        class="paging-click row-data"
        style="float: right; margin-bottom: 10px"
    >
      <el-pagination
          background
          @size-change="handleSizeChange"
          :current-page.sync="pageNumber"
          @current-change="handleCurrentChange"
          :page-sizes="$pageListDefaultNew"
          :page-size="maxPageItem"
          :layout="$pageLayoutDefault"
          :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import checkPermission from "@/utils/permission.js";
import AlbumService from "../../services/AlbumService";
import {mapState} from "vuex";
import NotifyMessage from "@/commonFuncion/NotifyMessage";

export default {
  components: {},
  data() {
    return {
      currentPagePagination: 1,
      pageTotal: this.$pageTotalDefault,
      maxPageItem: this.$pageSizeDefaultNew,
      pageNumber: 1,
      // listClass: [],
      layout: "sizes, prev, pager, next",
      total: undefined,
      pageSize: 12,
      currentPageNumber: 1,
      showProgress: false,
      albumTypesOption: ["Trường", "Lớp"],
      checkApprove: false,
      listIdApproveAlbum: [],
      // offset : this.currentPageNumber*this.pageSize-this.pageSize,
      pageList: [3, 6, 8, 10],
      multipleSelectionEmployee: [],
      showCreateDialog: false,
      showUpdateDialog: false,
      employeeData: [],
      statusEmployee: [],
      departmentList: [],
      idList: [],
      // listGrade: [],
      dataSearch: {
        idGrade: "",
        idClass: "",
        albumType: "",
        dateList: ["", ""],
      },
      albumResponseList: [],
    };
  },
  created() {
    if (this.getAppTypeUserLogin === "plus") {
      this.$store.dispatch('classStore/fetchDataClassListCommon');
    } else {
      this.$store.dispatch('classStore/fetchDataClassInTeacher');
    }
  },
  computed: {
    ...mapState('classStore', ['classInTeacher']),
    ...mapState('classStore', ['classListCommon']),
    // eslint-disable-next-line vue/return-in-computed-property
    listClass() {
      if (this.getAppTypeUserLogin === "plus") {
        return this.classListCommon;
      } else if (this.getAppTypeUserLogin === "teacher") {
        return this.classInTeacher;
      }
      return [];
    },
    getAppTypeUserLogin() {
      return this.$store.state.auth.user.appType;
    },
  },
  watch: {
    listClass: {
      handler(newVal) {
        if (newVal.length > 0 && this.getAppTypeUserLogin === "teacher") {
          this.getIdClassInTeacher();
        }
      },
    }
  },
  methods: {
    checkPermission,
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold";
    },
    //highlight cho row theo điều kiện
    tableRowStyle({row}) {
      row.id;
    },
    getIdClassInTeacher() {
      this.dataSearch.idClass = this.classList.filter((x) => x.checked)[0].id;
      this.searchByProperties();
    },
    // click chọn số bản ghi trên 1 trang
    handleSizeChange(maxPageItem) {
      this.maxPageItem = maxPageItem;
      this.searchByProperties();
    },
    //click chọn số trang
    handleCurrentChange(pageNumber) {
      this.currentPagePagination = pageNumber
      this.pageNumber = pageNumber;
      this.searchByProperties();
    },
    handleCommandAction(command) {
      switch (command) {
        case "approveAlbum" :
          this.updateMultiApproveAlbumMethod();
          break;
        case "unApproveAlbum" :
          this.updateMultiUnApproveAlbum();
          break;
        case "exportExcel" :
          this.exportExcelEmployee();
          break;
        case "deleteMultiAlbum" :
          this.deleteMultiAlbum();
          break;
      }
    },

    //click checkbox of row
    handleSelectionChange(val) {
      this.multipleSelectionEmployee = val;
    },
    //click edit at row
    handleEdit(index, row) {
      this.showUpdateDialog = true;
      console.log(row);
    },
    //click delete at row
    handleDelete(index, row) {
      // console.log("delete " + index, row);
      this.$confirm(
          "Bạn có chắc chắn muốn xóa nhân viên đã chọn?",
          "Thông báo!"
      );
      console.log(row);
    },
    createEmployeeDialog() {
      this.showCreateDialog = true;
    },
    dialogCloseCreateMethod() {

      this.showCreateDialog = false;
      this.searchByProperties();
    },
    dialogCloseUpdateMethod() {
      // this.getAllClass();
      this.showUpdateDialog = false;
      this.searchByProperties();
    },
    searchHeaderMethod() {
      this.pageNumber = 1;
      this.currentPagePagination = 1;
      this.searchByProperties();
    },
    checkDateList() {
      if (this.dataSearch.dateList == null) {
        this.dataSearch.dateList = ["", ""];
      }
    },
    searchByProperties() {
      this.checkDateList();
      AlbumService.searchalbumNew(
          this.pageNumber,
          this.maxPageItem,
          this.dataSearch.dateList[0],
          this.dataSearch.dateList[1],
          this.dataSearch.idGrade,
          this.dataSearch.idClass,
          this.dataSearch.albumType
      )
          .then((resp) => {
            this.albumResponseList = resp.data.data.responseList.map(item => {
              return {
                ...item,
                checkDownload: false
              };
            });

            this.total = resp.data.data.total;
            // this.getTotalRows();
          })
          .catch((err) => {
            this.total = 1;
            console.log(err);
            this.albumResponseList = [];
          });
    },

    getTotalRows() {
      AlbumService.getTotalItems().then((res) => {
        this.total = res.data.data;
      });
    },
    download1(album) {
      album.checkDownload = true;
      AlbumService.dowloadAlbum1(album.id)
          .then((response) => {
            this.reponse = response.data.data;
            const link = document.createElement("a");
            link.href = this.reponse;
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            console.log(err);
          }).finally(() => {
        setTimeout(() => {
          album.checkDownload = false;
          NotifyMessage.notifySuccess("Tải album thành công!")
        }, 3000);

      })
    },
    deleteAlbum(id) {
      this.$confirm(
          "Bạn có chắc chắn muốn xóa Album ảnh đã chọn?",
          "Thông báo!",
          {
            distinguishCancelAndClose: true,
            confirmButtonText: "Có",
            closeOnClickModal: false,
            cancelButtonText: "Không",
          }
      ).then(() => {
        AlbumService.deleteAlbum(id)
            .then((res) => {
              if (res.data) {
                NotifyMessage.notifySuccess("Xóa album thành công!")
                this.searchByProperties();
                // this.$router.go("/student-quality/album");
              } else {
                NotifyMessage.notifyError("Xóa album thất bại")
              }
            })
            .catch((err) => {
              console.log(err);
              NotifyMessage.notifyError("Lỗi xóa ảnh")
            });
      });
    },
    deleteMultiAlbum() {
      if (this.listIdApproveAlbum.length > 0) {
        this.$confirm(
            "Bạn có chắc chắn muốn xóa Album ảnh đã chọn?",
            "Thông báo!",
            {
              distinguishCancelAndClose: true,
              confirmButtonText: "Có",
              closeOnClickModal: false,
              cancelButtonText: "Không",
            }
        ).then(() => {
          AlbumService.deleteMultiAlbum(this.listIdApproveAlbum)
              .then((res) => {
                if (res.data) {
                  NotifyMessage.notifySuccess("Xóa album thành công!")
                  this.listIdApproveAlbum = [];
                  // this.$router.go("/student-quality/album");
                  this.searchByProperties();
                } else {
                  NotifyMessage.notifyError("Xóa album thất bại")
                }
              })
              .catch((err) => {
                console.log(err);
                NotifyMessage.notifyError("Lỗi xóa ảnh")
              });
        });
      } else {
        NotifyMessage.notifyError("Bạn chưa chọn Album nào")
      }
    },

    checkApproveAlbum(id, checkApprove) {
      let indexOf = this.listIdApproveAlbum.indexOf(id);
      if (checkApprove === true) {
        if (indexOf === -1) {
          this.listIdApproveAlbum.push(id);
        }
      } else {
        if (indexOf !== -1) {
          this.listIdApproveAlbum.splice(indexOf, 1);
        }
      }
    },
    updateMultiApproveAlbumMethod() {
      AlbumService.updateMultiApproveAlbum(this.listIdApproveAlbum)
          .then((res) => {
            NotifyMessage.notifySuccess("Duyệt album thành công!")
            console.log(res.data);
            this.searchByProperties();
          })
          .catch((err) => {
            console.log(err);
            NotifyMessage.notifyError("Duyệt album thất bại")
            this.searchByProperties();
          });
    },
    updateMultiUnApproveAlbum() {
      AlbumService.updateMultiUnApproveAlbum(this.listIdApproveAlbum)
          .then(() => {
            NotifyMessage.notifySuccess("Hủy duyệt album thành công!")
            this.searchByProperties();
          })
          .catch((err) => {
            console.log(err);
            NotifyMessage.notifyError("Hủy duyệt album thất bại")
            this.searchByProperties();
          });
    },
    albumResponseAlbumName(albumResponse) {
      if (albumResponse.urlPictureFirst != null) {
        return albumResponse.albumName;
      } else {
        return albumResponse.albumName + " - No Picture";
      }
    },
  },

  beforeMount() {
    this.getTotalRows();
    this.searchByProperties();
  },
};
</script>

<style lang="scss" scoped>
.content {
  .row-data {
    margin-bottom: 20px;
  }

  .button-over {
    border-radius: 0;
    margin-left: 3px;
  }

  .button-left {
    margin-right: 5px;
    width: 145px;
  }

  .button-click-left {
    display: inline-block;
  }

  .button-click {
    float: right;
  }

  .table-content {
    .el-table {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border: 1px solid #78a5e7;
    }
  }

  .paging-click {
    .el-pagination {
      text-align: right;
      margin: 30px 0;
    }
  }

  /deep/ .el-table th.gutter {
    background-color: #78a5e7;
  }

  // /deep/.el-table--scrollable-y .el-table__body-wrapper {
  //   overflow-y: auto;
  //   margin-top: -1px;
  // }
  /deep/ .el-table .cell {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
    word-break: break-all;
    line-height: 23px;
    padding-right: 10px;
    // max-height: 50px;
  }

  /deep/ .el-range-editor.el-input__inner {
    display: inline-flex;
    align-items: center;
    padding: 3px 10px;
    width: 260px;
  }

  /deep/ .el-input-group > .el-input__inner {
    vertical-align: middle;
    display: table-cell;
    margin-left: 5px;
  }

  /deep/ .element.style {
    width: 257px !important;
  }
}

.el-dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0;
  margin: 3px 0;
  background-color: rgb(95, 180, 74);
  border: 1px solid #ebeef5;
  border-radius: 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.el-dropdown-menu li {
  color: white;
  border-top: 1px solid white;
  font-family: Arial, Helvetica, sans-serif;
}

li.el-select-dropdown__item.hover {
  background: rgb(111, 142, 189);
}

/deep/ .el-table td,
/deep/ .el-table th {
  padding: 6px 0;
}

.click-fullname {
  border: none;
  padding: 0;
  color: blue;
}

.click-fullname:hover {
  color: blue;
  background: none;
}

.inline-block-div:hover {
  opacity: 0.6 !important;
  transition: border-bottom 0.1s ease-in-out, opacity 0.1s ease-in-out;
}
</style>
