var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticStyle: { "margin-top": "5px" } }, [
        _c(
          "div",
          { staticClass: "button-click-left" },
          [
            _c("el-date-picker", {
              staticClass: "button-left-status",
              staticStyle: { width: "265px" },
              attrs: {
                clearable: true,
                "value-format": "yyyy-MM-dd",
                format: "dd-MM-yyyy",
                type: "daterange",
                "unlink-panels": "",
                "range-separator": "-",
                "start-placeholder": "Ngày bắt đầu",
                "end-placeholder": "Ngày kết thúc",
              },
              on: {
                change: function ($event) {
                  return _vm.searchByProperties()
                },
              },
              model: {
                value: _vm.dataSearch.dateList,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "dateList", $$v)
                },
                expression: "dataSearch.dateList",
              },
            }),
            _c(
              "el-select",
              {
                staticClass: "button-left",
                staticStyle: { "margin-left": "5px" },
                attrs: { placeholder: "Chọn lớp", clearable: "" },
                on: {
                  change: function ($event) {
                    return _vm.searchHeaderMethod()
                  },
                },
                model: {
                  value: _vm.dataSearch.idClass,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "idClass", $$v)
                  },
                  expression: "dataSearch.idClass",
                },
              },
              _vm._l(_vm.listClass, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.className, value: item.id },
                })
              }),
              1
            ),
            _vm.getAppTypeUserLogin === "plus"
              ? _c(
                  "el-select",
                  {
                    staticClass: "button-left",
                    attrs: { placeholder: "Tất cả", clearable: "" },
                    on: {
                      change: function ($event) {
                        return _vm.searchHeaderMethod()
                      },
                    },
                    model: {
                      value: _vm.dataSearch.albumType,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearch, "albumType", $$v)
                      },
                      expression: "dataSearch.albumType",
                    },
                  },
                  _vm._l(_vm.albumTypesOption, function (item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item, value: item },
                    })
                  }),
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.checkPermission(["studentQuality_ablum_update"])
          ? _c(
              "div",
              { staticClass: "button-click row-data" },
              [
                _c(
                  "el-dropdown",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { path: "/student-quality/album/add" } } },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "button-over",
                            attrs: { type: "success" },
                          },
                          [_vm._v("Tạo Album")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-dropdown",
                  { on: { command: _vm.handleCommandAction } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button-over",
                        attrs: { type: "success" },
                      },
                      [
                        _vm._v("\n          Tác vụ\n          "),
                        _c("i", { staticClass: "el-icon-caret-bottom" }),
                      ]
                    ),
                    _c(
                      "el-dropdown-menu",
                      { staticClass: "el-dropdown-menu-container" },
                      [
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "approveAlbum" } },
                          [_vm._v("Duyệt Album\n          ")]
                        ),
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "unApproveAlbum" } },
                          [_vm._v("Hủy duyệt Album\n          ")]
                        ),
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "deleteMultiAlbum" } },
                          [_vm._v("Xóa Album\n          ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.total > 0
        ? _c(
            "el-row",
            {
              staticStyle: {
                display: "flex",
                "flex-wrap": "wrap",
                width: "100%",
                "margin-top": "10px",
                border: "1px solid rgb(96 99 102 / 19%)",
                "overflow-y": "scroll",
                "max-height": "730px",
                "padding-left": "50px",
              },
            },
            _vm._l(_vm.albumResponseList, function (albumResponse) {
              return _c(
                "div",
                {
                  key: albumResponse.id,
                  staticStyle: {
                    flex: "0 0 223px",
                    margin: "14px",
                    width: "223px",
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "router-link",
                      staticStyle: {
                        "text-decoration": "none",
                        height: "200px",
                      },
                      attrs: {
                        to: {
                          path:
                            "/student-quality/album-detail/" + albumResponse.id,
                        },
                      },
                    },
                    [
                      albumResponse.albumNew
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                background: "orange",
                                position: "absolute",
                                "z-index": "1",
                              },
                            },
                            [_vm._v("Mới")]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "inline-block-div" },
                        [
                          albumResponse.urlPictureFirst != null
                            ? _c("el-image", {
                                staticStyle: { width: "100%", height: "200px" },
                                attrs: { src: albumResponse.urlShowPicture },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", [
                        _c(
                          "h4",
                          {
                            staticClass: "demonstration",
                            staticStyle: {
                              color: "#30313499",
                              "text-align": "left",
                              "margin-left": "5px",
                              overflow: "hidden",
                              "max-height": "20px",
                              "text-overflow": "ellipsis",
                              "white-space": "nowrap",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.albumResponseAlbumName(albumResponse))
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "span",
                        {
                          staticClass: "demonstration",
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                        },
                        [_vm._v(_vm._s(albumResponse.className))]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "demonstration",
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                        },
                        [_vm._v(_vm._s(albumResponse.albumType))]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "demonstration",
                          staticStyle: {
                            "margin-right": "5px",
                            float: "right",
                          },
                        },
                        [
                          _vm._v(_vm._s(albumResponse.pictureNumber) + " "),
                          _c("i", {
                            staticClass: "el-icon-picture",
                            staticStyle: { color: "#6298d0" },
                          }),
                        ]
                      ),
                      _c("br"),
                      _c("el-checkbox", {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { "albumResponse.checkApprove": "" },
                        on: {
                          change: function ($event) {
                            return _vm.checkApproveAlbum(
                              albumResponse.id,
                              albumResponse.checkApporove
                            )
                          },
                        },
                        model: {
                          value: albumResponse.checkApporove,
                          callback: function ($$v) {
                            _vm.$set(albumResponse, "checkApporove", $$v)
                          },
                          expression: "albumResponse.checkApporove",
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "demonstration",
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDateTime")(
                                albumResponse.createdDate
                              )
                            )
                          ),
                        ]
                      ),
                      albumResponse.albumView
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                background: "#67c23a",
                                color: "white",
                                "font-size": "20px",
                                "text-align": "center",
                              },
                            },
                            [
                              _c("span", [_vm._v(" Đang hiện")]),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    background: "#67c23a",
                                    "margin-left": "10px",
                                    border: "none",
                                  },
                                  attrs: {
                                    loading: albumResponse.checkDownload,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.download1(albumResponse)
                                    },
                                  },
                                },
                                [
                                  !albumResponse.checkDownload
                                    ? _c("i", {
                                        staticClass: "el-icon-download",
                                        staticStyle: { color: "white" },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _vm.checkPermission([
                                "studentQuality_ablum_update",
                              ])
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        background: "rgb(103, 194, 58)",
                                        border: "none",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteAlbum(
                                            albumResponse.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-delete",
                                        staticStyle: { color: "white" },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                background: "#409eff",
                                color: "white",
                                "font-size": "20px",
                                "text-align": "center",
                              },
                            },
                            [
                              _c("span", [_vm._v("Đã ẩn")]),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    background: "#409eff",
                                    "margin-left": "10px",
                                    border: "none",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.download1(albumResponse.id)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-download",
                                    staticStyle: { color: "white" },
                                  }),
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    background: "#409eff",
                                    border: "none",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteAlbum(albumResponse.id)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-delete",
                                    staticStyle: { color: "white" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          )
        : _c(
            "el-row",
            {
              staticStyle: {
                display: "flex",
                "flex-wrap": "wrap",
                width: "100%",
                "margin-top": "10px",
                border: "1px solid rgba(96, 99, 102, 0.19)",
                "max-height": "730px",
                "padding-left": "50px",
                height: "100px",
                "justify-content": "center",
                "align-items": "center",
                color: "#4b9af9",
              },
            },
            [_c("div", [_vm._v("Không có dữ liệu!")])]
          ),
      _vm.total > 0
        ? _c(
            "div",
            {
              staticClass: "paging-click row-data",
              staticStyle: { float: "right", "margin-bottom": "10px" },
            },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.pageNumber,
                  "page-sizes": _vm.$pageListDefaultNew,
                  "page-size": _vm.maxPageItem,
                  layout: _vm.$pageLayoutDefault,
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "update:currentPage": function ($event) {
                    _vm.pageNumber = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.pageNumber = $event
                  },
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }